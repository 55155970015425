<template>
  <a-layout id="components-layout-demo-custom-trigger" style="height: 100vh">
    <a-layout-sider
      v-model="collapsed"
      :trigger="null"
      collapsible
      theme="light"
      style="background: #eef2f8"
    >
      <div class="logo" @click="JumpToMain">
        <img src="@/assets/images/logo.png" height="26px" /><br />
        <div v-show="!collapsed" style="white-space: nowrap; padding-top: 12px">
          AI工地可视化管理平台
        </div>
      </div>
      <side-menu></side-menu>
      <div class="div-trigger">
        <a-icon
          class="trigger"
          :type="collapsed ? 'menu-unfold' : 'menu-fold'"
          @click="() => (collapsed = !collapsed)"
        />
      </div>
    </a-layout-sider>
    <a-layout>
      <a-layout-header class="main-header" style="">
        <!-- top -->
        <breadcrumb />
      </a-layout-header>
      <a-layout-content class="main-content">
        <router-view />
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>
<script>
import SideMenu from "./SideMenu.vue";
import Breadcrumb from "./Breadcrumb.vue";
export default {
  components: { SideMenu, Breadcrumb },
  data() {
    return {
      collapsed: false,
      rootSubmenuKeys: ["sub1", "sub2", "sub4"],
      openKeys: ["sub1"],
    };
  },
  created() {},
  methods: {
    JumpToMain() {
      this.$router.push({ path: "/" });
    },
  },
};
</script>
<style>
#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  margin: 24px 20px 20px 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #34333b;
  cursor: pointer;
}
#components-layout-demo-custom-trigger .div-trigger {
  width: 100%;
  position: absolute;
  bottom: 0;
}
.main-header {
  background: #fff;
  padding: 0;
  text-align: left;
}
.main-content {
  padding: 0 16px;
  background: #fff;
  min-height: 280px;
  height: 100%;
  overflow: auto;
}
</style>
