<template>
  <!-- :defaultSelectedKeys="activeIndex" -->
  <a-menu
    theme="light"
    mode="inline"
    :open-keys="openKeys"
    :selected-keys="selectedKeys"
    style="text-align: left"
    @openChange="onOpenChange"
  >
    <a-sub-menu v-for="item in menu" :key="item.name">
      <div
        slot="title"
        @click="changeRouter_father(item)"
        v-on:mouseenter="onMouseEnter(item)"
        v-on:mouseleave="onMouseLeave(item)"
      >
        <a-icon :type="item.meta.icon" v-if="item.meta.icon" />
        <span v-if="item.meta.imgUrl" class="titleIcon">
          <img
            :src="
              require('../assets/images/titleIcon/icon-' +
                item.meta.imgUrl +
                '.png')
            "
            alt=""
            class="titleIcon"
          />
          <!-- <img :src="item.meta.imgUrl" alt="" class="titleIcon" /> -->
        </span>

        <span>{{ item.meta.title }}</span>
      </div>
      <a-menu-item
        v-for="child in item.children"
        :key="child.name"
        @click="changeRouter(child.name, child.path)"
        v-show="child.meta.showMenu"
      >
        {{ child.meta.title }}
      </a-menu-item>
    </a-sub-menu>
  </a-menu>
</template>
<script>
import { asyncRouterMap } from "@/config/router.config.js";

export default {
  name: "SideMenu",
  data() {
    return {
      rootSubmenuKeys: [], // 所有一级菜单
      openKeys: [], // 当前展开的一级菜单
      selectedKeys: [], // 当前选中的二级菜单
      menu: [],
      activeIndex: "",
      R_Right: [],
      current: [],
      clickmenuName: "",
    };
  },
  created() {
    setTimeout(() => {
      // this.current = this.$store.state.menu.current;
      // console.log("this.$store.state", this.current);
      // console.log("this.$store.state", this.$store.state.user.roles);
      let system = this.$store.state.user.system;
      // console.log("system", system);
      // if (system == "xc") {
      //   console.log("xc", this.$store.state.user.roles);
      // if (this.$store.state.user.roles != "") {
      //   this.R_Right = this.$store.state.user.roles.split(",");
      //   console.log("roles111", this.R_Right);
      // } else
      if (sessionStorage.getItem("roles")) {
        this.R_Right = sessionStorage.getItem("roles").split(",");
        // console.log("sessionStorage-roles", this.R_Right);
      } else {
        if (system == "jf") {
          this.R_Right = this.$store.state.user.rolesJF.split(",");
        } else {
          this.R_Right = this.$store.state.user.roles.split(",");
        }
        // console.log("roles111", this.R_Right);
      }

      // } else if (system == "jf") {
      //   // this.R_Right=this.$store.state.user.rolesJf
      //   console.log("jf", this.$store.state.user.rolesJf);
      // }
      // this.getMenu(this.current);
    }, 100);
    // 加判断
  },
  watch: {
    // 监听到路由地址的改变
    "$route.name": function (newValue) {
      // console.log(" 监听到路由地址的改变", newValue);
      this.$store.commit("SET_CURRENT", newValue);
      localStorage.setItem("path", newValue);
      this.selectedKeys = [newValue];
      this.getMenu(newValue);
      this.onOpenChange(this.openKeys);
    },
    // 监听vuex的可以显示的左侧菜单栏的信息
    "$store.state.user.roles"(newVal) {
      // console.log("监听vuex的可以显示的左侧菜单栏的信息", newVal);
      if (newVal) {
        this.R_Right = newVal.split(",");
      }
    },
    "$store.state.user.system": {
      handler(newValue) {
        if (newValue == "xc") {
          this.R_Right = this.$store.state.user.roles.split(",");
          sessionStorage.setItem(
            "roles",
            this.$store.state.user.roles.split(",")
          );
          // console.log("watch--vuex-roles", this.$store.state.user.roles);
          // this.$store.commit("SET_CURRENT", "");
        } else if (newValue == "jf") {
          this.R_Right = this.$store.state.user.rolesJF.split(",");
          sessionStorage.setItem(
            "roles",
            this.$store.state.user.rolesJF.split(",")
          );
          // console.log("watch--vuex-rolesJF", this.$store.state.user.rolesJF);
          // this.$store.commit("SET_CURRENT", "");
        }
        // 切换系统时，跳转到第一个一级菜单下的第一个二级菜单
        setTimeout(() => {
          // console.log(" this.menu[0]", this.menu);
          if (this.menu[0].children) {
            this.$store.commit("SET_CURRENT", this.menu[0].children[0].name);
            this.$router.push({
              path: this.menu[0].children[0].path,
            });
          }
        }, 1);
      },
      deep: true,
      immediate: true,
    },
    R_Right: {
      handler(newValue) {
        const current = this.$store.state.menu.current;
        this.getMenu(current);
      },

      deep: true,
      // immediate: true,
    },
    deep: true,
    immediate: true,
  },

  methods: {
    onOpenChange(openKeys) {
      if (openKeys.length != 0) {
        const latestOpenKey = openKeys.find((key) => {
          this.openKeys.indexOf(key) === -1;
        });
        if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
          this.openKeys = openKeys.slice(-1);
        } else {
          this.openKeys = latestOpenKey ? [latestOpenKey] : [];
        }
      } else {
        // this.openKeys = [];
      }
    },
    onRouteChange(name) {
      this.selectedKeys = [name];
    },
    getMenu(current) {
      let pageArrs = [];
      for (let i = 0; i < asyncRouterMap.length; i++) {
        let pageArr = [];
        for (let k = 0; k < asyncRouterMap[i].children.length; k++) {
          /*  if (this.R_Right.indexOf(asyncRouterMap[i].children[k].name) != -1) {
            pageArr.push(asyncRouterMap[i].children[k]);
          } */

          if (this.R_Right.indexOf(asyncRouterMap[i].children[k].name) != -1) {
            pageArr.push(asyncRouterMap[i].children[k]);
          }
        }
        if (pageArr.length != 0) {
          let pageObject = {};
          pageObject.path = asyncRouterMap[i].path;
          pageObject.name = asyncRouterMap[i].name;
          pageObject.meta = asyncRouterMap[i].meta;
          pageObject.component = asyncRouterMap[i].component;
          pageObject.redirect = asyncRouterMap[i].redirect;
          pageObject.children = pageArr;
          pageArrs.push(pageObject);
        }
      }
      this.menu = pageArrs;
      if (current) {
        // 有指定菜单，显示指定菜单
        // console.log("有指定菜单", current);
        this.selectedKeys.push(current);
        asyncRouterMap.forEach((el) => {
          this.rootSubmenuKeys.push(el.name);
          for (let i = 0; i < el.children.length; i++) {
            if (el.children[i].name === current) {
              // this.openKeys.push(el.name);
              this.openKeys = [el.name];
              // 图标切换
              if (el.children[i].meta.imgUrl) {
                el.children[i].meta.imgUrl = el.children[i].name + "Blue";
              }
              // console.log(this.openKeys);
              return;
            }
          }
        });
      } else {
        // 没有指定菜单，默认显示第一个
        let hasOpenKeys = false;
        asyncRouterMap.forEach((el) => {
          this.rootSubmenuKeys.push(el.name);
          if (!hasOpenKeys) {
            this.openKeys.push(el.name);
            // console.log(el.name);
            hasOpenKeys = true;
            // 默认显示第一个二级菜单
            this.selectedKeys.push(el.children[0].name);
            console.log("没有指定菜单", el.children[0].path);
            // 图标切换
            if (el.children[i].meta.imgUrl) {
              el.children[i].meta.imgUrl = el.children[0].name + "Blue";
            }
          }
        });
      }
    },
    changeRouter(name, path) {
      localStorage.removeItem("path");
      // console.log("this.selectedKeys", this.selectedKeys);
      this.selectedKeys = [name];
      if (name === "DataV") {
        const { href } = this.$router.resolve({ path: "/DataView" });
        window.open(href, "_blank");
      } else if (name === "Patrol") {
        localStorage.removeItem("patrolParam");
        const { href } = this.$router.resolve({ path: "/PatrolView" });
        window.open(href, "_blank");
      } else if (name === "CaseField") {
        const { href } = this.$router.resolve({ path: "/CaseFieldView" });
        window.open(href, "_blank");
      } else if (name === "customPreview") {
        //这个要和菜单栏里的名字一样，不然不会打开新窗口
        const { href } = this.$router.resolve({ path: "/customPreviewView" });
        window.open(href, "_blank");
      } else if (name === "BoardTotal") {
        const { href } = this.$router.resolve({ path: "/BoardTotalView" });
        window.open(href, "_blank");
      } else if (name === "ExhibitionBoard") {
        const { href } = this.$router.resolve({ path: "/ExhibitionBoardView" });
        window.open(href, "_blank");
      } else if (name === "MapFieldboard") {
        const { href } = this.$router.resolve({ path: "/MapFieldboard" });
        window.open(href, "_blank");
      } else if (name === "videoRecord") {
        const { href } = this.$router.resolve({ path: "/VideoRecordView" });
        window.open(href, "_blank");
      }
      // 新增 测试
      else if (name == "Index2x3") {
        localStorage.removeItem("patrolParam");
        const { href } = this.$router.resolve({ path: "/Index2x3" });
        window.open(href, "_blank");
      }
      //截止
      else {
        this.$store.commit("SET_CURRENT", name);
        this.$router.push({ path });
        return;
      }
    },
    // 点击一级菜单，如果一级菜单为数据看板，直接显示数据看板页面
    changeRouter_father(item) {
      if (item.name == "data") {
        localStorage.setItem("path", "DailyMonitor");
        this.$store.commit("SET_CURRENT", "DailyMonitor");
        this.$router.push({
          path: "./DailyMonitor",
        });
      } else {
        // localStorage.setItem("path", item.children[0].name);
        this.$router.push({
          path: "." + item.children[0].path,
        });
      }
      // console.log("item", item);
      // let NewAsyncRouterMap = JSON.parse(JSON.stringify(asyncRouterMap));
      // 点击菜单栏切换的时候切换图标
      asyncRouterMap.forEach((el) => {
        if (el.meta.imgUrl) {
          if (el.name == item.name) {
            el.meta.imgUrl = el.name + "Blue";
          } else {
            el.meta.imgUrl = el.name;
          }
        }
      });
      this.clickmenuName = item.name;
      // asyncRouterMap = JSON.parse(JSON.stringify(NewAsyncRouterMap));
      // item.meta.imgUrl = "template2Blue";
    },
    onMouseEnter(item) {
      asyncRouterMap.forEach((el) => {
        if (el.meta.imgUrl) {
          if (el.name == item.name) {
            el.meta.imgUrl = el.name + "Blue";
          } else if (el.name != this.clickmenuName) {
            el.meta.imgUrl = el.name;
          } else {
            // el.meta.imgUrl = el.name;
          }
        }
      });
    },
    onMouseLeave(item) {
      asyncRouterMap.forEach((el) => {
        if (el.meta.imgUrl) {
          if (el.name == item.name && el.name != this.clickmenuName) {
            el.meta.imgUrl = el.name;
          }
        }
      });
    },
  },

  destroyed() {
    localStorage.removeItem("path");
  },
};
</script>
<style lang="less" >
.ant-menu {
  background: #eef2f8;
}
.ant-menu-submenu > .ant-menu {
  background: #eef2f8;
}
.ant-menu .ant-menu-item-selected {
  background: linear-gradient(259.32deg, #0395f5 7.93%, #0349fa 92.07%);
  color: #fff;
}
.titleIcon {
  img {
    width: 10px;
    height: 10px;
    margin-right: 10px;
  }
}
</style>
