<template>
  <div class="breadcrumb">
    <span class="parent" v-show="!pathHide">{{ parent.meta.title }}</span>
    <a-icon type="right" class="icon" v-show="!pathHide" />
    <span class="child">{{ child.meta.title }}</span>
    <span class="switch" v-show="pathHide">
      <img src="../assets/images/switch.svg"/>
      <span v-show="path ==='/SiteMap'" @click="changeRoute('SiteList')">切换至列表模式</span>
      <span v-show="path ==='/SiteList'" @click="changeRoute('SiteMap')">切换至工地地图模式</span>
    </span>
    <user-info ref="UserInfo"></user-info>
  </div>
</template>
<script>
import { asyncRouterMap } from "@/config/router.config.js";
import UserInfo from "./UserInfo.vue";
export default {
  name: "Breadcrumb",
  components: { UserInfo },
  data() {
    return {
      routes: asyncRouterMap,
      parent: {},
      child: {},
      path: "",
      pathHide: false,
    };
  },
  watch: {
    // 监听到路由地址的改变
    "$route.path": function (newValue) {
      this.onRouteChange(newValue);
    },
  },
  created() {
    this.onRouteChange(this.$route.path);
  },
  methods: {
    onRouteChange(route) {
      // 处理面包屑
      this.routes.forEach((el) => {
        el.children.forEach((c) => {
          if (route === c.path) {
            this.parent = el;
            this.child = c;
            return;
          }
        });
      });
      // 判断是否是地图/工地管理
      this.path = route;
      this.pathHide = (route === '/SiteMap' || route === '/SiteList')
    },
    changeRoute(name) {
      this.$store.commit("SET_CURRENT", name);
      this.$router.push({ path: '/' + name });
    },
  },
};
</script>
<style lang="less" scoped>
.breadcrumb {
  position: relative;
  z-index: 10;
  background-color: #fff;
  padding-left: 16px;
  font-size: 20px;
}
.icon {
  font-size: 10px;
  padding: 0 10px;
  display: inline-block;
  vertical-align: middle;
}
.parent {
  color: #999;
  vertical-align: middle;
}
.child {
  vertical-align: middle;
}
.switch {
  font-size: 12px;
  color: #65646C;
  margin-left: 25px;
  cursor: pointer;
  &:hover {
    color: #1890ff;
  }
  img {
    width: 22px;
    margin-right: 5px;
  }
}
</style>
