<template>
  <div>
    <div class="user-info">
      <div class="systemTypeBtns" v-if="isShowsystemTypeBtns">
        <a-radio-group
          button-style="solid"
          @change="changeSystem"
          v-model="value"
        >
          <a-radio-button value="xc"> 现场管理系统 </a-radio-button>
          <a-radio-button value="jf"> 交付系统 </a-radio-button>
        </a-radio-group>
      </div>
      <a-dropdown>
        <a @click="(e) => e.preventDefault()">
          <img src="../assets/images/user_img.png" class="head-img" />
          <span class="user-name">{{ this.$store.state.user.name }}</span>
        </a>
        <a-menu slot="overlay">
          <a-menu-item>
            <a @click="changePwd()">修改密码</a>
          </a-menu-item>
          <a-menu-item>
            <a @click="doLogOut()">退出登录</a>
          </a-menu-item>
        </a-menu>
      </a-dropdown>
    </div>
    <div>
      <user-edit-pass
        :u_id="u_id"
        v-if="changePwdShow"
        @onClose="onClose"
      ></user-edit-pass>
    </div>
  </div>
</template>
<script>
import UserEditPass from "./UserEditPass";

import { DoLogout } from "@/api/login";
export default {
  name: "UserInfo",
  components: { UserEditPass },
  data() {
    return {
      name: this.$store.state.user.name,
      u_id: this.$store.state.user.id,
      changePwdShow: false,
      systemType: "现场管理系统",
      value: "xc",
      isShowsystemTypeBtns: false,
    };
  },
  created() {
    setTimeout(() => {
      this.u_id = this.$store.state.user.id;
      let roles = this.$store.state.user.roles;
      let rolesJF = this.$store.state.user.rolesJF;
      // console.log("roles", roles);
      // console.log("rolesJF", rolesJF);
      // if (roles == "") {
      //   this.isShowsystemTypeBtns = false;
      //   this.value = "jf";
      //   this.$store.commit("SET_SYSTEM", "jf");
      // } else if (rolesJF == "") {
      //   this.isShowsystemTypeBtns = false;
      //   this.value = "xc";
      //   this.$store.commit("SET_SYSTEM", "xc");
      // } else {
      //   this.isShowsystemTypeBtns = true;
      //   this.$store.commit("SET_SYSTEM", "xc");
      // }
      if (rolesJF == "" || roles == "") {
        this.isShowsystemTypeBtns = false;
      } else {
        this.isShowsystemTypeBtns = true;
      }
      let SET_SYSTEMValue = localStorage.getItem("SET_SYSTEM");

      if (SET_SYSTEMValue) {
        // console.log("SET_SYSTEMValue", SET_SYSTEMValue);
        this.$store.commit("SET_SYSTEM", SET_SYSTEMValue);
        this.value = SET_SYSTEMValue;
        if (SET_SYSTEMValue == "xc") {
          // this.isShowsystemTypeBtns = true;
        } else {
          // this.isShowsystemTypeBtns = true;
        }
      } else {
        if (rolesJF == "") {
          // this.isShowsystemTypeBtns = false;
          this.value = "xc";
          this.$store.commit("SET_SYSTEM", "xc");
        } else {
          // this.isShowsystemTypeBtns = true;
          this.$store.commit("SET_SYSTEM", "jf");
        }
      }
    }, 100);
  },
  methods: {
    doLogOut() {
      DoLogout()
        .then((response) => {
          if (response.code == "200") {
            localStorage.removeItem("userMsg");
            this.$store.commit("SET_ROLES", "");
            this.$store.commit("SET_ROLESJF", "");
            sessionStorage.removeItem("token");
            this.$router.push({ path: "/Login" });
            sessionStorage.removeItem("roles");

            localStorage.removeItem("path", "");
            localStorage.removeItem("SET_SYSTEM");
            this.$store.commit("SET_CURRENT", "");
            this.$store.commit("SET_SYSTEM", "");
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
      // localStorage.removeItem("userMsg");
      // this.$store.commit("SET_CURRENT", "");
    },
    onClose() {
      this.changePwdShow = false;
    },
    changePwd() {
      this.changePwdShow = true;
    },
    changeSystem(e) {
      let value = `${e.target.value}`;
      // console.log("选择现场管理系统或交付系统", value);
      this.$store.commit("SET_SYSTEM", value);
      localStorage.setItem("SET_SYSTEM", value);
      // console.log("vuex---system", this.$store.state.user.system);
    },
  },
};
</script>
<style lang="less" scoped>
.user-info {
  position: fixed;
  top: 0;
  right: 40px;
  display: flex;
}
.head-img {
  padding-right: 6px;
}
.user-name {
  color: #595959;
  font-size: 14px;
  vertical-align: middle;
}
.systemTypeBtns {
  margin-right: 30px;
}
</style>
